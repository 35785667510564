<template>
	<div>
		<div class="px-4 py-4">
			<section aria-labelledby="notes-title">
				<div class="bg-white shadow sm:rounded-lg sm:overflow-hidden">
					<div class="divide-y divide-gray-200">
						<div class="px-4 pt-6 sm:px-6">
							<h2 id="responses-title" class="text-2xl font-medium text-gray-900">Ticket Log</h2>
							<div class="mt-6 flex flex-col">
								<div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
									<div class="inline-block min-w-full py-2 align-middle">
										<div class="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5">
											<table class="min-w-full divide-y divide-gray-300">
												<thead class="bg-gray-50">
													<tr>
														<th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">ID</th>
														<th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">ACTIVITY</th>
														<th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Log</th>
														<th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Caused by</th>
													</tr>
												</thead>
												<tbody class="bg-white">
													<tr
														v-for="(log, logIdx) in activityStore.activityLogs.sort((a, b) => {
															return b.id - a.id;
														})"
														:key="log.id"
														:class="logIdx % 2 === 0 ? undefined : 'bg-gray-50'"
													>
														<td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{ log.id }}</td>
														<td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ log.action }}</td>
														<td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ log.log }}</td>
														<td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ log.creator.first_name }} {{ log.creator.last_name }}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import { useActivityStore } from '@modules/activities/store';

	const activityStore = useActivityStore();
</script>

<style scoped></style>
